import React, { useEffect, useState } from "react";

import { useLocation } from "@reach/router";
import BlockContent from "@sanity/block-content-to-react";
import classNames from "classnames";
import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../components/Button/Button";
import Eyecatcher from "../../components/Eyecatcher/Eyecatcher";
import InputField from "../../components/InputField/InputField";
import Masonry from "../../components/Masonry/Masonry";
import Section from "../../components/Section/Section";
import SidebarFilters from "../../components/SidebarFilters/SidebarFilters";
import TextHeader from "../../components/TextHeader/TextHeader";
import { addFilter, removeFilter, setSearchTerm } from "../../features/ArtworkFilterSlice";
import useMediaQuery from "../../hooks/useMediaQuery";
import { ExpandIcon24, SearchIcon24 } from "../../icons";
import ArtworkCard from "../Artwork/ArtworkCard";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";
import GetCallToActionContext from "../utils/GetCallToActionContext";
import GetIconComponents from "../utils/GetIconComponents";

const CollectionOverviewPage = ({
  data: {
    page,
    artworks,
  },
}) => {
  const {
    searchTerm,
    filters,
  } = useSelector((state) => state.artworkFilter);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = page.i18n_lang || "de-DE";
  const { language } = useI18next();
  const langPath = language === "de-DE" ? "" : `/${language}`;

  const filterDefinition = [
    {
      id: "kind",
      name: t("genre"),
      options: [
        {
          href: `${langPath}/${t("sammlung_slug")}/${t("mehr-zu-grafik").toLowerCase()}`,
          label: t("graphic"),
          value: "Grafik",
        },
        {
          href: `${langPath}/${t("sammlung_slug")}/${t("mehr-zu-kinetik").toLowerCase()}`,
          label: t("kinetic"),
          value: "Kinetik",
        },
        {
          href: `${langPath}/${t("sammlung_slug")}/${t("mehr-zu-fotografie").toLowerCase()}`,
          label: t("photography"),
          value: "Fotografie",
        },
        {
          href: `${langPath}/${t("sammlung_slug")}/${t("mehr-zu-gemaelde").toLowerCase()}`,
          label: t("painting"),
          value: "Gemälde",
        },
        {
          href: `${langPath}/${t("sammlung_slug")}/${t("mehr-zu-skulptur").toLowerCase()}`,
          label: t("sculpture"),
          value: "Skulptur",
        },
        {
          href: `${langPath}/${t("sammlung_slug")}/${t("mehr-zu-kunst-im-oeffentlichen-raum").toLowerCase()}`,
          label: t("artInPublicSpaces"),
          value: "Kunst im öffentlichen Raum",
        },
      ],
    },
  ];

  const filterKind = (item) => {
    for (const [key, value] of Object.entries(filters)) {
      if (value.length > 0 && !value.includes(item[key])) {
        return false;
      }
    }

    return true;
  };

  const filterSearchterm = (item) => {
    if (!searchTerm || searchTerm === "") {
      return true;
    }

    return item.title?.toLowerCase().includes(searchTerm.toLowerCase())
      || item.artist?.name?.toLowerCase().includes(searchTerm.toLowerCase());
  };

  // State for the list
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [list, setList] = useState(
    // eslint-disable-next-line no-unsafe-optional-chaining
    [...artworks.nodes?.sort((a, b) => 0.5 - Math.random())?.slice(0, 10)],
  );

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false);

  // State to trigger oad more
  const [updateFilter, setUpdateFilter] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(artworks.nodes?.length > 10);

  const location = useLocation();

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const handleUpdateFilter = (event) => {
    setUpdateFilter(true);
    dispatch(setSearchTerm(event.target.value));
  };

  const isMobile = useMediaQuery("(max-width: 1024px)");

  let currentLength = 10;

  useEffect(() => {
    if (new URLSearchParams(location.search).get("searchbar") !== "") {
      setUpdateFilter(true);
      dispatch(setSearchTerm(new URLSearchParams(location.search)?.get("searchbar")));
    }
  }, []);

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      currentLength = list.length;
      const isMore = currentLength < artworks.nodes?.length;
      const nextResults = isMore
        ? artworks.nodes?.filter(filterKind)
          .filter(filterSearchterm).slice(currentLength, currentLength + 9)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  // Check if there is more
  useEffect(() => {
    const isMore = list.length < artworks.nodes?.filter(filterKind)
      .filter(filterSearchterm).length;
    setHasMore(isMore);
  }, [list]) //eslint-disable-line

  // Check if there is more
  useEffect(() => {
    if (updateFilter) {
      setList(artworks.nodes?.filter(filterKind)
        .filter(filterSearchterm)
        .slice(0, currentLength < 30 ? 30 : currentLength));
      setUpdateFilter(false);
    }
  }, [updateFilter]) //eslint-disable-line

  return (
    <MainLayout breadcrumb={<Breadcrumb id={page.id} />}>
      <TextHeader title={page.title}>
        {page._rawQuickInfoText && (
          <BlockContent
            blocks={page._rawQuickInfoText}
          />
        )}
      </TextHeader>
      <SidebarFilters
        className="my-36 lg:my-90"
        filters={filterDefinition}
        filterState={filters}
        onChange={(event) => {
          setUpdateFilter(true);
          event.target.checked
            ? dispatch(addFilter(
              {
                section: event.target.name.slice(0, -2),
                value: event.target.value,
              },
            ))
            : dispatch(removeFilter(
              {
                section: event.target.name.slice(0, -2),
                value: event.target.value,
              },
            ));
        }}
        headline={`${(new Intl.NumberFormat(lang).format(artworks.totalCount))} ${t("artworksOnline")}`}
        searchbar={(
          <InputField
            id="searchbar"
            message=""
            name="searchbar"
            placeholder={t("searchbarPlaceholder")}
            onChange={handleUpdateFilter}
            value={searchTerm}
            preComponent={(
              <div className="inline-flex bg-black text-white w-full h-full justify-center items-center">
                <SearchIcon24 />
              </div>
            )}
          />
        )}
      >
        <Masonry breakpointCols={isMobile ? 2 : 3}>
          {list.slice(0, 6)
            .map(
              (artwork, index) => (
                <ArtworkCard data={artwork} className={classNames({ "mt-60": index === 0 || index === 2 })} />
              ),
            )}
        </Masonry>
        <Section>
          <Eyecatcher
            headline={page.eyecatcher?.headline}
            button={
              (
                <Button
                  color="white"
                  title="Button"
                  href={GetCallToActionContext(page.eyecatcher?.callToAction).path}
                >{GetCallToActionContext(page.eyecatcher?.callToAction).title}
                </Button>
              )
            }
            description={<BlockContent blocks={page.eyecatcher?._rawText} />}
            logo={
              React.createElement(GetIconComponents().components[page.eyecatcher?.Icon?.component])
            }
          />
        </Section>
        <Section>
          <Masonry breakpointCols={isMobile ? 2 : 3}>
            {list.slice(7, 100)
              .map(
                (artwork, index) => (
                  <ArtworkCard data={artwork} className={classNames({ "mt-60": index === 0 || index === 2 })} />
                ),
              )}
          </Masonry>
        </Section>
        <Section>
          <div className="grid place-items-center ">
            {hasMore ? (
              <Button onClick={handleLoadMore} title={t("loadMore")} variant="secondary"><ExpandIcon24 /> {t("loadMore")}</Button>
            ) : (
              <p>{t("noFurtherArtworks")}</p>
            )}
          </div>
        </Section>
      </SidebarFilters>
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityCollectionOverview(id: {eq: $id}) {
      i18n_lang
      id
      title
      _rawQuickInfoText
      ourArtworks {
        ...ArtworkCard
      }
      newArtworks {
        ...ArtworkCard
      }
      eyecatcher {
        headline
        _rawText
        Icon {
          component
          icon {
            asset {
              url
              gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR, width: 720)
            }
          }
        }
        callToAction {
          linkType
          blank
          title
          href
          _rawInternalLink(resolveReferences: {maxDepth: 1})
        }
      }
      Metadata {
        ...MetaData
      }
    }
    artworks: allSanityArtwork(filter: {images: {elemMatch: {_key: {ne: null}, asset: {_id: {ne: null}}}}}) {
      totalCount
      nodes {
        ...ArtworkCard
        id
        kind
        slug {
          current
        }
        artist {
          name
        }
        title
        year
        images {
          ...ImageWithPreview
          alt
        }
      }
    }
  }
`;

CollectionOverviewPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default CollectionOverviewPage;
