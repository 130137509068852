import React, { Fragment, useState } from "react";

import { Dialog, Disclosure, Transition } from "@headlessui/react";
import classNames from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";

import { AddIcon24, FilterIcon24 } from "../../icons";
import Checkbox from "../Checkbox/Checkbox";

const SidebarFilters = ({
  children,
  filters,
  filterState,
  headline,
  onChange,
  searchbar,
}) => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <div>
        {/* Mobile filter dialog */}
        <div className="relative z-40 lg:hidden m-18">
          {searchbar}
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                    <div className="flex items-center justify-between px-4">
                      <h2 className="h5">Filter</h2>
                      <button
                        type="button"
                        className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <AddIcon24 className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    {/* Filters */}
                    <form className="mt-4">
                      {filters.map((section) => (
                        <Disclosure as="div" key={section.name} className="border-t border-gray-200 pt-4 pb-4">
                          {({ open }) => (
                            <fieldset>
                              <legend className="w-full px-2">
                                <Disclosure.Button className="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
                                  <h2 className="h5">{section.name}</h2>
                                  <span className="ml-6 flex h-7 items-center">
                                    <AddIcon24
                                      className={classNames(open ? "-rotate-45" : "rotate-0", "transform")}
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Disclosure.Button>
                              </legend>
                              <Disclosure.Panel className="px-4 pt-4 pb-2">
                                <div className="space-y-6">
                                  {section.options.map((option, optionIdx) => (
                                    <div key={option.value} className="flex-col items-center">
                                      <Checkbox
                                        id={`${section.id}-${optionIdx}-mobile`}
                                        label={option.label}
                                        name={`${section.id}[]`}
                                        defaultValue={option.value}
                                        defaultChecked={
                                          filterState[section.id]?.includes(option.value)
                                        }
                                        onChange={onChange}
                                      />
                                      {option.href && (
                                        <a className="text-dark-grey text-small" href={option.href} title={`${t("whatMeans")} ${option.label}?`}>
                                          {t("whatMeans")} {option.label}?
                                        </a>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </fieldset>
                          )}
                        </Disclosure>
                      ))}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>

        <div className="pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4 m-18">
          <aside>
            <h2 className="h5 sr-only">Filter</h2>

            <button
              type="button"
              className="inline-flex items-center lg:hidden"
              onClick={() => setMobileFiltersOpen(true)}
            >
              <FilterIcon24 className="mr-10" aria-hidden="true" />
              <span className="h5 underline">Filter</span>
            </button>
            <div className="hidden lg:block">
              <form className="space-y-30 divide-gray-200">
                <h2 className="h5 mb-20">{headline}</h2>
                <div className="mr-60">{searchbar}</div>
                {filters.map((section, sectionIdx) => (
                  <div key={section.name} className={sectionIdx === 0 ? null : "pt-10"}>
                    <fieldset>
                      <legend className="h5">{section.name}</legend>
                      <div className="space-y-3 pt-6">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex-col items-center">
                            <Checkbox
                              id={`${section.id}-${optionIdx}-mobile`}
                              label={option.label}
                              name={`${section.id}[]`}
                              defaultValue={option.value}
                              defaultChecked={filterState[section.id]?.includes(option.value)}
                              onChange={onChange}
                            />
                            {option.href && (
                              <a className="text-dark-grey text-small" href={option.href} title={`${t("whatMeans")} ${option.label}?`}>
                                {t("whatMeans")} {option.label}
                              </a>
                            )}
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                ))}
              </form>
            </div>
          </aside>

          {/* Product grid */}
          <div className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3">
            {/* Replace with your content */}
            { children }
            {/* /End replace */}
          </div>
        </div>
      </div>
    </div>
  );
};

SidebarFilters.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterState: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.array.isRequired,
  headline: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  searchbar: PropTypes.node,
};

SidebarFilters.defaultProps = {
  filterState: null,
  headline: "",
  searchbar: null,
};

export default SidebarFilters;
